import Highway from '@dogstudio/highway';
import { TimelineLite } from 'gsap';

class Fade extends Highway.Transition {
    in({ from, to, done}){       //Seite die Eingeblendet wird from = von wo wir kommen / to = wo hin / done = funktion die sagt dass fertig 

        const tl = new TimelineLite();
        tl.fromTo(to, 0.5, {left: '-100%', top: '50%'}, {left: '0%'}).fromTo(to, 0.5, {height: '2vh'}, {height: '90vh', top: '10%', onComplete: function(){
            from.remove(); //nicht benutzte Container entfernen
            done();
        }})
        .fromTo(to.children[0], 2, {opacity: '0'}, {opacity:'1'}); 

    }
    out({from, done}){      //Seite die Ausgeblendet wird, sobald done gemacht wird wird in abgespielt 
        done();
    }
};

export default Fade;